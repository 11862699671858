<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">Write the chemical formula for each of the following compounds:</p>

      <p class="mb-3">
        a)
        <stemble-latex :content="nameA" />
      </p>
      <chemical-notation-input v-model="inputs.compoundA" class="mb-7" :disabled="!allowEditing" />

      <p class="mb-3">
        b)
        <stemble-latex :content="nameB" />
      </p>
      <chemical-notation-input v-model="inputs.compoundB" class="mb-7" :disabled="!allowEditing" />

      <p class="mb-3">
        c)
        <stemble-latex :content="nameC" />
      </p>
      <chemical-notation-input v-model="inputs.compoundC" class="mb-7" :disabled="!allowEditing" />

      <p class="mb-3">
        d)
        <stemble-latex :content="nameD" />
      </p>
      <chemical-notation-input v-model="inputs.compoundD" class="mb-7" :disabled="!allowEditing" />

      <p class="mb-3">
        e)
        <stemble-latex :content="nameE" />
      </p>
      <chemical-notation-input v-model="inputs.compoundE" class="mb-7" :disabled="!allowEditing" />

      <p class="mb-3">
        f)
        <stemble-latex :content="nameF" />
      </p>
      <chemical-notation-input v-model="inputs.compoundF" class="mb-7" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'Question215',
  components: {
    StembleLatex,
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        compoundA: null,
        compoundB: null,
        compoundC: null,
        compoundD: null,
        compoundE: null,
        compoundF: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    nameA() {
      if (this.versionVariableA.value === 1) {
        return '$\\text{barium chloride}$';
      } else if (this.versionVariableA.value === 2) {
        return '$\\text{magnesium fluoride}$';
      } else if (this.versionVariableA.value === 3) {
        return '$\\text{barium iodide}$';
      } else if (this.versionVariableA.value === 4) {
        return '$\\text{calcium bromide}$';
      } else {
        return '';
      }
    },
    versionVariableB() {
      return this.taskState.getValueBySymbol('versionVariableB').content;
    },
    nameB() {
      if (this.versionVariableB.value === 1) {
        return '$\\text{magnesium nitride}$';
      } else if (this.versionVariableB.value === 2) {
        return '$\\text{barium nitride}$';
      } else if (this.versionVariableB.value === 3) {
        return '$\\text{calcium nitride}$';
      } else if (this.versionVariableB.value === 4) {
        return '$\\text{barium phosphide}$';
      } else {
        return '';
      }
    },
    versionVariableC() {
      return this.taskState.getValueBySymbol('versionVariableC').content;
    },
    nameC() {
      if (this.versionVariableC.value === 1) {
        return '$\\text{sulfur dioxide}$';
      } else if (this.versionVariableC.value === 2) {
        return '$\\text{carbon disulfide}$';
      } else if (this.versionVariableC.value === 3) {
        return '$\\text{sulfur hexafluoride}$';
      } else if (this.versionVariableC.value === 4) {
        return '$\\text{phosphorus trichloride}$';
      } else {
        return '';
      }
    },
    versionVariableD() {
      return this.taskState.getValueBySymbol('versionVariableD').content;
    },
    nameD() {
      if (this.versionVariableD.value === 1) {
        return '$\\text{nitrogen trifluoride}$';
      } else if (this.versionVariableD.value === 2) {
        return '$\\text{phosphorus tribromide}$';
      } else if (this.versionVariableD.value === 3) {
        return '$\\text{selenium dichloride}$';
      } else if (this.versionVariableD.value === 4) {
        return '$\\text{phosphorus pentachloride}$';
      } else {
        return '';
      }
    },
    versionVariableE() {
      return this.taskState.getValueBySymbol('versionVariableE').content;
    },
    nameE() {
      if (this.versionVariableE.value === 1) {
        return '$\\text{oxygen difluoride}$';
      } else if (this.versionVariableE.value === 2) {
        return '$\\text{dinitrogen pentoxide}$';
      } else if (this.versionVariableE.value === 3) {
        return '$\\text{dinitrogen tetroxide}$';
      } else if (this.versionVariableE.value === 4) {
        return '$\\text{chlorine dioxide}$';
      } else {
        return '';
      }
    },
    versionVariableF() {
      return this.taskState.getValueBySymbol('versionVariableF').content;
    },
    nameF() {
      if (this.versionVariableF.value === 1) {
        return '$\\text{tin(IV) chloride}$';
      } else if (this.versionVariableF.value === 2) {
        return '$\\text{iron(III) chloride}$';
      } else if (this.versionVariableF.value === 3) {
        return '$\\text{iron(II) bromide}$';
      } else if (this.versionVariableF.value === 4) {
        return '$\\text{chromium(III) iodide}$';
      } else {
        return '';
      }
    },
  },
};
</script>
